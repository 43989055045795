import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { SocialIcon } from 'react-social-icons'

class FooterLinks extends Component {
  state = {
    socialUrls: [
      'https://twitter.com/Innova22Labs',
      'https://github.com/Innovation-Labs-Technical-Hub',
      'https://www.linkedin.com/company/innovation-labs-2022',
    ],
  }
  render() {
    return (
      <React.Fragment>
        <div className="footer-alt">
          <Container>
            <Row>
              <Col lg="12">
                <div className="float-start pull-none">
                  <p className="copy-rights text-muted mb-3 mb-sm-0">
                    2023 © Innovation Labs
                  </p>
                </div>
                <div className="float-end pull-none ">
                  {this.state.socialUrls.map((url, index) => (
                    <SocialIcon style={{ marginLeft: 10 }} url={url} />
                  ))}
                </div>
                <div className="clearfix"></div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default FooterLinks
